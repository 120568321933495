import React from 'react'
import styles from './block-button.module.scss'
import {Link} from 'gatsby'

const BlockButton = ({to, text}) => {
    return(
        <Link className={styles.button} to={to}>{text}</Link>
    )
}

export default BlockButton;