import React from 'react'
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const MobileHomeImage = () => {
    const image = useStaticQuery(graphql`
    query{
      fileName: file(relativePath: { eq: "images/HomePagePhotoMobile.jpg" }) {
        childImageSharp{
          fluid(maxWidth: 818, maxHeight:600){
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `)

    return (
        <Img fluid={image.fileName.childImageSharp.fluid} alt="" />
    )
}

export default MobileHomeImage;