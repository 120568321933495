import React from "react"
import Layout from '../components/layout'
import indexStyles from './index.module.scss'
import SocialIcons from "../components/social-icons"
import DesktopHomeImage from "../components/dektop-home-image"
import MobileHomeImage from "../components/mobile-home-image"
import SubscribeForm from "../components/subscribe-form"
import FeaturedPost from "../components/featured-post"
import WelcomeBlock from "../components/welcome-block"
import SEO from "../components/seo"
import LatestVideo from "../components/latest-video"

const IndexPage = () => {

  return (
    <Layout>
      <SEO title="Home" keywords={[ `reflect`, `simple things`, `bullet journal`]}/>
      <div className={indexStyles.container}>
        <div className={indexStyles.containerHomeDesktop}>          
          <DesktopHomeImage />
        </div>
        <div className={indexStyles.containerHomeMobile}>
          <MobileHomeImage />
        </div>        
        <div className={indexStyles.containerWelcome}>
          <WelcomeBlock />
          <SocialIcons iconColor="#1C1C1C" iconSize="48"/>
        </div>        
        <div className={indexStyles.containerSecondary}>          
          <FeaturedPost/>
        </div>
        <div className={indexStyles.containerPrimary}>
          <div className={indexStyles.leftContainer}>
            <h1 className={indexStyles.secondaryHeading}>Mindful Musings</h1>
            <p className={indexStyles.secondaryText}>A newsletter with thoughts, insights and reflections. Join me!</p>
          </div>
          <div className={indexStyles.rightContainer}>            
            <SubscribeForm />
          </div>
        </div>
        <div className={indexStyles.containerSecondary}>
          <LatestVideo/>
        </div>
      </div>
    </Layout>  
  )
}

export default IndexPage;