import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import styles from './featured-post.module.scss'
import Img from 'gatsby-image'
import BlockButton from './block-button'

const FeaturedPost = () => {
  const data = useStaticQuery(graphql`
    query{
      allContentfulBlogPost(
        sort: {
          fields: datePublished,
          order: DESC
        }    
        limit:1
      ){
        edges{
          node{
            title
            slug
            excerpt
            datePublished (formatString:"MMMM Do, YYYY")
            postContent{
              json
            }
            mainImage{
              file{
                url
              }
              
              fluid(maxWidth: 500){
                ...GatsbyContentfulFluid_withWebp
              }
  
              localFile{
                absolutePath
                publicURL
                childImageSharp{
                  fluid(maxWidth:500){
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    `)

  return (
    <div className={styles.container}>
      <div className={styles.containerLeft}>
        <div className={styles.featuredPostHeading}>Featured Post</div>
        <Link to={`/blog/${data.allContentfulBlogPost.edges[0].node.slug}`}><h2>{data.allContentfulBlogPost.edges[0].node.title}</h2></Link>        
        <p>{data.allContentfulBlogPost.edges[0].node.datePublished}</p>        
        <BlockButton to={`/blog/${data.allContentfulBlogPost.edges[0].node.slug}`} text="Continue Reading" />
      </div>
      <div className={styles.containerRight}>
        <Link aria-label="Featured blog post" to={`/blog/${data.allContentfulBlogPost.edges[0].node.slug}`}><Img fluid={data.allContentfulBlogPost.edges[0].node.mainImage.localFile.childImageSharp.fluid} alt="" /></Link>
      </div>
    </div>
  )
}

export default FeaturedPost;