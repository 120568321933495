import React from 'react';
import styles from './welcome-block.module.scss';
import {Link} from 'gatsby'
import {OutboundLink} from 'gatsby-plugin-google-analytics'

const WelcomeBlock = () => {
    return (
        <div>
            <h1>Welcome!</h1>
            <p>I’m Raksha. A bullet journaller, content creator and idle thinker. I enjoy exploring thoughts and ideas on how we can all live happy and meaningful lives full of freedom, fun and creativity.</p>
            <p className={styles.smallText}>I capture my thoughts and ideas in my bullet journal and share these on my <OutboundLink href="https://www.youtube.com/reflectwithraksha" target="_blank" rel="noreferrer">YouTube</OutboundLink> channel and over on Instagram <OutboundLink href="https://www.instagram.com/reflectwithraksha/" target="_blank" rel="noreferrer">@reflectwithraksha</OutboundLink></p>
            <p className={styles.smallText}><Link to="/blog">In my blog</Link>, I write about intentional living through bullet journalling, along with observations from my favourite books and journaling tools.</p>
            <p className={styles.smallText}>Subscribe to my newsletter below. It's a cosy little space to slow down and take the time to explore living a life that you love. I’d love for you to join me!</p>
        </div>
    )
}

export default WelcomeBlock;