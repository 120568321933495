import React from 'react'
import styles from './latest-video.module.scss'
import YoutubeVideo from './youtube-video'

const LatestVideo = () => {
    return(
        <div className={styles.container}>           
            <div className={styles.leftContainer}>
                <h2>Watch my latest video</h2> 
            </div>
            <div className={styles.rightContainer}>
                <YoutubeVideo url="https://www.youtube-nocookie.com/embed?max-results=1&listType=playlist&list=PLiri0BlocGfD0ZMN-a_25lmR8BmDvOLmC"/>
            </div>
        </div>
    )
}

export default LatestVideo;